<template>
	<div class="main-container">
		<div class="right-year">
						<div class="right-year-lr">年份</div>
						<div class="block">
                  <el-date-picker
                  style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid #BAE0FF;"
                  class="picker"
                     v-model="year"
                     type="year"
                     @change="yearChange"
                     placeholder="选择年份">
                  </el-date-picker>
               </div>
			   <div class="tab-searh">
					<span class="right-year-lr">企业名称</span>
					<input type="text" v-model.trim="applant"  placeholder="请输入企业名称">
					<span class="tab-searh-span" @click="searh()">搜索</span>
			   </div>
			   <!-- <div class="chosearea">
				<span class="span1" :class="{active1:areaIndex==0}" @click="selectarea(0)">区域内代理机构</span>
				<span class="span2" :class="{active2:areaIndex==1}" @click="selectarea(1)">为区域企业代理过商标业务的代理机构</span>
			   </div> -->
			   <div class="daochu" @click="daochu">导出</div>
		</div>
		<el-table :data="tableData" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
		    <el-table-column type="index" label="排名" width="280"  />
		    <el-table-column prop="agentName" label="企业名称" width="280" />
		    <el-table-column prop="applyCnt" sortable width="280" label="申请量" align="center" />
		    <el-table-column prop="validCnt" width="280" label="注册量" align="center" />
		    <el-table-column prop="validCnt" width="280" align="center" sortable label="通过率">	
				<template v-slot="scope">
					<span style="color: #28C445;" v-if="scope.row.pass">{{(scope.row.pass * 100).toFixed(2)}}%</span>
					<span style="color: #28C445;" v-else>0</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import { Loading } from "element-ui";
	export default {
  name: '',
  data(){
    return {
	  year:'',
	  applant:'',
	  tableData:[],
	  height:570,
	  province:"",
      city:"",
	  county:"",
	  level:"",
	  areaIndex:0
    }
  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	     yearChange(val){
			// console.log(val)
			this.applant = ""
			this.getdata()
		 },
		 selectarea(val){
			this.areaIndex = val
		 },
		 searh(){
				this.getdata()
			},
		 getdata(){
				let url = ''
				if (this.year == ""||this.year == null) {
					this.year = ''
					url = 'getAgencyAllByCnt'
				} else {
					url = 'getAgencyYearByCnt'
				}
			this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/' + url,
					{
					"applyYear":new Date(this.year).getFullYear(),
					"agentname":this.applant,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county
					}
            ).then((res)=>{
				// console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)	
			})
		 },
		 daochu(){
			if(this.tableData.length == 0 ){
				return false;
			}
			Loading.service({ fullscreen: true })
			    let url = ''
				if (this.year == ""||this.year == null) {
					this.year = ''
					url = 'getAgencyAllExcel'
				} else {
					url = 'getAgencyYearExcel'
				}
			this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/' + url,
					{
					"applyYear":new Date(this.year).getFullYear(),
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county
					}
            ).then((res)=>{
				// console.log(res)
			 if(res.data.code == 200){
				Loading.service().close();
				const link = document.createElement("a");
				link.style.display = "none";
				link.href = res.data.data;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				}
			}).catch((err) =>{
                       Loading.service().close();
					   console.log(err);
                    })
		 }
  },
   mounted() {
	this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
     this.getdata()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	.right-year{
   height: 40px;
   margin-bottom: 25px;
   margin-left: 30px;
}
.right-year>div{
   float: left;
   margin-left: 50px;
}
.right-year-lr{
   line-height: 40px;
   font-size: 16px;
   font-family: PingFang SC-Medium, PingFang SC;
   color: #BAE0FF;
}
:deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-input__inner){
	padding-left: 40px!important;
}
:deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
.tab-searh>input{
		width: 250px;
		height: 38px;
		outline: none;
		font-size: 16px;
		padding-left: 10px;
		background-color: transparent;
		border-radius: 4px;
		border: 1px solid #BAE0FF;
		margin: 0 25px;
		color: #fff;
	}
	input::-webkit-input-placeholder{
		color: #BAE0FF;
	}
	.tab-searh-span{
		display: inline-block;
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		width: 76px;
		height: 32px;
		cursor: pointer;
		color: #ffffff;
		font-size: 18px;
		background: #264C8F;
	}
.chosearea{
	height: 40px;
	margin-left: 300px!important;
}
.chosearea>span{
	display: inline-block;
	line-height: 40px;
	font-size: 18px;
	font-family: PingFang SC-Bold, PingFang SC;
	color: #FFFFFF;
	text-align: center;
	cursor: pointer;
}
.span1{
	width: 159px;
	background: url('../../../static/images/agent3.png') 100% no-repeat;
}
.span2{
	width: 338px;
	background: url('../../../static/images/agent2.png') 100% no-repeat;
}
.active1{
	background: url('../../../static/images/agent1.png') 100% no-repeat!important;
}
.active2{
	background: url('../../../static/images/agent4.png') 100% no-repeat!important;
}
 .daochu{
	display:inline-block;
	width: 80px;
	color: #fff !important;
	height: 32px;
	text-align: center;
	margin-left: 540px!important;
	line-height: 32px;
	background: #264C8F;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid #264C8F;
	/* margin-left: 250px!important; */
}

</style>
