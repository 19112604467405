<template>
	<div class="main-container">
		<div class="tab-searh">
			<span>申请人</span>
			<input type="text" v-model.trim="applant"  placeholder="请输入申请人">
			<span class="tab-searh-span" @click="searh()">搜索</span>
			<el-select v-if="index==3" v-model="value" placeholder="请选择" @change="chose" style="width:115px;height:36px;margin-left:140px;">
                 <el-option value="">请选择</el-option>
				<el-option v-for="item in optionm"
                        :key="item.id"
                        :label="item.id"
                        :value="item.id"></el-option>
               
            </el-select>
			<span class="daochu2" v-if="index==3" @click="daochu">导出</span>
			<span class="daochu" v-else @click="daochu">导出</span>
		</div>
		<el-table :data="tableData" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
		    <el-table-column prop="sbmc" label="名称" />
			<el-table-column label="图片">
				<template #default="scope">
					<el-image v-if="scope.row.imgUrl" :src="scope.row.imgUrl" 
					 style="width: 50px;height: 50px;" />
					 <!-- lazy -->
				</template>
			</el-table-column>
		    <el-table-column prop="intCls" label="国际分类">
				<template v-slot="scope">
					<span>{{scope.row.intCls || scope.row.intcls}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="regId" label="注册号" >
				<template v-slot="scope">
					<span>{{scope.row.regId || scope.row.intregno}}</span>
				</template>
			</el-table-column>
		    <el-table-column prop="intregdate" v-if="index==0" label="注册日期" />
		    <el-table-column prop="status" v-if="index==0" label="状态">
				<template v-slot="scope">
					<!-- <span v-if="scope.row.status == 'Active'" style="color:#28C445">已注册</span>
					<span v-else style="color:#28C445">审查中</span> -->
					<span style="color:#28C445">{{scope.row.status}}</span>
				</template>
			</el-table-column>
		    <el-table-column prop="validDate" label="有效期" v-if="index!=0">
				<template v-slot="scope">
					<span style="color:#28C445" v-if="scope.row.validDate">{{scope.row.validDate.slice(0,10)}}</span>
				</template>
			</el-table-column>
		   <el-table-column prop="applicant" label="申请人">
				<template v-slot="scope">
					<span>{{scope.row.applicant || scope.row.sbsq}}</span>
				</template>
			</el-table-column>
		</el-table>
		    <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1000px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
	</div>
</template>
<script>
import { Loading } from "element-ui";
export default {
  name: '',
  data(){
    return {
	  applant:'',
	  from:0,
	  total:0,
	  size:20,
	  tableData:[],
	  height:510,
	  province:"",
      city:"",
	  county:"",
	  level:"",
      town:"",
      intCls:"",
	  optionm:[ { id: "01"},
        { id: "02"},
        { id: "03"},
        { id: "04"},
        { id: "05"},
        { id: "06"},
        { id: "07"},
        { id: "08"},
        { id: "09"},
        { id: "10"},
        { id: "11"},
        { id: "12"},
        { id: "13"},
        { id: "14"},
        { id: "15"},
        { id: "16"},
        { id: "17"},
        { id: "18"},
        { id: "19"},
        { id: "20"},
        { id: "21"},
        { id: "22"},
        { id: "23"},
        { id: "24"},
        { id: "25"},
        { id: "26"},
        { id: "27"},
        { id: "28"},
        { id: "29"},
        { id: "30"},
        { id: "31"},
        { id: "32"},
        { id: "33"},
        { id: "34"},
        { id: "35"},
        { id: "36"},
        { id: "37"},
        { id: "38"},
        { id: "39"},
        { id: "40"},
        { id: "41"},
        { id: "42"},
        { id: "43"},
        { id: "44"},
        { id: "45"}],
		value:''
    }
  },
  props:{
		index: {
				type: Number,
				default: () => {
					return 0
				},
			}
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
	 chose(){
		this.intCls = this.value;
		this.from =0;
		this.getData()
	 },
	 getData(){
		// console.log(this.index)
				let url = ''
				if (this.index == 0) {
					url = 'getMadridData'
				} else if (this.index == 1) {
					url = 'renew'
				} else if (this.index == 2) {
					url = 'broaden'
				}else if(this.index == 3){
					url = 'getAllTrademarkData'
				}
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/' + url,
					{
					"sbmc": '',
					"applicant": this.applant,
					"regId": '',
					"intCls": '',
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					"intCls":this.intCls,
					"town":this.town
					}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)		
	 },
	 daochu(){
		if(this.tableData.length == 0 ){
				return false;
			}
			Loading.service({ fullscreen: true })
		       let url = ''
				if (this.index == 0) {
					url = 'getMDLDataExcel'
				} else if (this.index == 1) {
					url = 'getRenewDataExcel'
				} else if (this.index == 2) {
					url = 'getBroadenDataExcel'
				}else if(this.index == 3){
					url = 'getAllTrademarkDataExcel'
				}
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/' + url,
					{
					"sbmc": '',
					"applicant": this.applant,
					"regId": '',
					"intCls": '',
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					"intCls":this.intCls,
					"town":this.town
					}
            ).then((res) => {
				if(res.data.code == 200){
					Loading.service().close();
					const link = document.createElement("a");
					link.style.display = "none";
					link.href = res.data.data;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
              } 
			).catch((err) =>{
                       Loading.service().close();
					   console.log(err);
                    })
	 },
useHeight(){
				return new Promise((res) => {
					this.$nextTick(() => {
						let heightCount = 0
						let mainEl = document.querySelector('.table-container' + this.index)
						heightCount = mainEl?.clientHeight
						heightCount = heightCount - 36 - 45
						this.height= heightCount
		                res(heightCount)
					})
				})				
			},
			searh(){
				this.from = 0
				this.getData()
			}     
  },
   mounted() {
	this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
       this.town=localStorage.getItem('town');
       this.intCls=localStorage.getItem('intCls');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
		if(this.town =="null"){
         this.town="";
      }
      if(this.intCls=="null"){
          this.intCls="";
      }
      this.getData();
	  this.useHeight()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	.tab-searh{
		color: #BAE0FF;
		margin: 0 0 25px 80px;
		
	}
	.tab-searh>input{
		width: 210px;
		height: 38px;
		outline: none;
		font-size: 16px;
		padding-left: 10px;
		background-color: transparent;
		border-radius: 4px;
		border: 1px solid #BAE0FF;
		margin: 0 25px;
		color: #fff;
	}
	input::-webkit-input-placeholder{
		color: #BAE0FF;
	}
	.tab-searh-span{
		display: inline-block;
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		width: 76px;
		height: 32px;
		color: #ffffff;
		cursor: pointer;
		font-size: 18px;
		background: #264C8F;
	}
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table .cell){
	font-size: 18px;
}
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF!important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
:deep(.el-input__inner){
	border:1px solid #91CAFF !important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
.daochu{
	display:inline-block;
	width: 80px;
	color: #fff !important;
	height: 32px;
	font-size: 18px;
	text-align: center;
	margin-left: 900px;
	line-height: 32px;
	background: #264C8F;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid #264C8F;
}
.daochu2{
	display:inline-block;
	width: 80px;
	color: #fff !important;
	height: 32px;
	font-size: 18px;
	text-align: center;
	margin-left: 650px;
	line-height: 32px;
	background: #264C8F;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid #264C8F;
}
.tab-searh :deep(.el-input__inner){
	padding-left: 20px!important;
	font-size: 16px;
}
:deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
</style>
