<template>
<scale-box>
  <div class="data">
         <div class="data-title">商标申请数据截止到<span>{{this.cnt.newDate}}</span>，核准注册截止到<span>{{this.cnt.ggDate}}</span><span class="to"  @click="to">专利数据统计</span><span class="logout" @click="loginout">退出登录</span></div>
        <div class="data-midlle">
         <div class="middle-left">
             <div class="middle-left-but" :class="active">
               <!-- <div @click="change('first')" class="divm" v-show="level !== '5'"> 
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'first'">
                   <span>区域指数</span>
                </div> -->
                <div @click="change('one')" class="div1" v-show="level !== '5'"> 
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'one'">
                   <span>年份数据统计和应用</span>
                </div>
                <div @click="change('two')" class="div2" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'two'">
                   <span>月份申请量</span>
                </div>
                <div @click="change('three')" class="div3" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'three'">
                   <span>月份注册量</span>
                </div>
                <div @click="change('four')" class="div4" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'four'">
                   <span>马德里申请</span>
                </div>
                <div @click="change('five')" class="div5">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'five'">
                   <span>商标续展</span>
                </div>
                <div @click="change('six')" class="div6">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'six'">
                   <span>商标宽展</span>
                </div>
                <div @click="change('seven')" class="div7" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'seven'">
                   <span>代理排行</span>
                </div>
                <div @click="change('eight')" class="div8" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'eight'">
                   <span>AI使用次数（天）</span>
                </div>
                <div @click="change('nine')" class="div9" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'nine'">
                   <span>AI使用次数（月）</span>
                </div>
                <div @click="change('ten')" class="div10" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'ten'">
                   <span>商标管理SAAS</span>
                </div>
                <div @click="change('ele')" class="div11">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'ele'">
                   <span>地理商标</span>
                </div>
                <div @click="change('twe')" class="div12">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'twe'">
                   <span>驰名商标</span>
                </div>
                <div @click="change('thr')" class="div13">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'thr'">
                   <span>所有商标</span>
                </div>
                <div @click="change('thf')" class="div14" v-show="level !== '5'">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'thf'">
                   <span>权限管理</span>
                </div>
            </div>
        </div>
        <div class="middle-right">
            <div class="right-div1">
               <div>
                  <div>{{this.cnt.applyCnt}}</div>
                  <img :src="require('/static/images/count1.png')" alt="">
                  <div>申请量</div>
               </div>
               <div>
                  <div>{{this.cnt.regCnt}}</div>
                  <img :src="require('/static/images/count2.png')" alt="">
                  <div>注册量</div>
               </div>
               <div>
                  <div>{{this.cnt.madridCnt}}</div>
                  <img :src="require('/static/images/count3.png')" alt="">
                  <div>马德里商标注册量</div>
               </div>
               <div>
                  <div>{{this.cnt.broadenCnt + this.cnt.renewCnt}}</div>
                  <img :src="require('/static/images/count4.png')" alt="">
                  <div>续展及宽展量</div>
               </div>
               <div>
                  <div>{{passRate()}}</div>
                  <img :src="require('/static/images/count5.png')" alt="">
                  <div>注册通过率</div>
               </div>
            </div>
            <div class="right-bottom1" v-if="active == 'first'">
              <AreaData :city="city" :county="county" :level="level"></AreaData>
            </div>
            <div class="right-bottom1" v-show="active == 'one'">
              <chart :option="data1.option" />
            </div>
            <div class="right-bottom1" v-show="active == 'two'">
               <div class="right-year">
						<div class="right-year-lr">选择年份</div>
						<div class="block">
                  <el-date-picker
                  style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid  #BAE0FF;"
                  class="picker"
                     :clearable="false"
                     v-model="year1"
                     type="year"
                     @change="yearChange1"
                     placeholder="选择年">
                  </el-date-picker>
               </div>
					</div>
              <chart  :option="data2.option" />
            </div>
             <div class="right-bottom1" v-show="active == 'three'">
               <div class="right-year">
						<div class="right-year-lr">选择年份</div>
						<div class="block">
                  <el-date-picker
                  style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid  #BAE0FF;"
                  class="picker"
                     :clearable="false"
                     v-model="year2"
                     type="year"
                     @change="yearChange2"
                     placeholder="选择年">
                  </el-date-picker>
               </div>
					</div>
              <chart  :option="data3.option" />
            </div>
            <div class="right-bottom1" v-if="active == 'four'">
              <Table :index="0"></Table>
            </div>
             <div class="right-bottom1" v-if="active == 'five'">
              <Table2 :index="1"></Table2>
            </div>
            <div class="right-bottom1" v-if="active == 'six'">
              <Table3 :index="2"></Table3>
            </div>
            <div class="right-bottom1" v-if="active == 'seven'">
              <AgentTable></AgentTable>
            </div>
            <div class="right-bottom1" v-if="active == 'eight'">
               <div class="right-year">
						<div class="right-year-lr">选择月份</div>
						<div class="block">
                  <el-date-picker
                     style="width:200px;border-radius: 4px 4px 4px 4px;border: 1px solid  #BAE0FF;"
                     class="picker"
                        :clearable="false"
                        v-model="pickmonth"
                        type="month"
                        @change="yearChange3"
                        placeholder="选择月份">
                     </el-date-picker>
               </div>
               <div class="count"> 共{{monthcount}}次</div>
					</div>
                <chart :option="data4.option" />
            </div>
            <div class="right-bottom1" v-if="active == 'nine'">
               <div class="right-year">
						<div class="right-year-lr">选择年份</div>
						<div class="block">
                  <el-date-picker
                     style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid  #BAE0FF;"
                     class="picker"
                        :clearable="false"
                        v-model="year3"
                        type="year"
                        @change="yearChange4"
                        placeholder="选择年份">
                     </el-date-picker>
               </div>
               <div class="count"> 共{{monthcount2}}次</div>
					</div>
                <chart :option="data5.option" />
            </div>
            <div class="right-bottom1" v-if="active == 'ten'">
              <Table10></Table10>
            </div>
            <div class="right-bottom1" v-if="active == 'ele'">
             <Dl1 :come="1"></Dl1>
            </div>
            <div class="right-bottom1" v-if="active == 'twe'">
             <Dl2 :come="2"></Dl2>
            </div>
            <div class="right-bottom1" v-if="active == 'thr'">
             <Table4 :index="3"></Table4>
            </div>
            <div class="right-bottom1" v-if="active == 'thf'">
             <SetPession ></SetPession>
            </div>
        </div>
      </div>
   </div>
   </scale-box>
</template>

<script>
import Chart from "../../components/chart/chart.vue";
import Table from "./Table.vue";
import Table2 from "./Table.vue";
import Table3 from "./Table.vue";
import Table4 from "./Table.vue";
import AgentTable from "./AgentTable.vue";
import Table10 from "./Table10.vue";
import Dl1 from "./dl.vue";
import Dl2 from "./dl.vue";
import AreaData from "./areaData.vue";
import SetPession from "./setPession.vue"
import {yearData,monthData,monthData2,monthData3,showdata} from "../../api/api";
import {
		getChartOption
	} from "./chart_options";
   import scaleBox from "./sclae-box";
export default {
  name: 'countdata',
  data(){
    return {
      active:'one',
      data1: {
            option: {},
         },
      data2: {
         option: {},
      },
      data3: {
         option: {},
      },
      data4: {
         option: {},
      },
      data5: {
         option: {},
      },
      year1:'2023',
      year2:'2023',
      pickmonth:'',
      monthcount:0,
      year3:'2023',
      monthcount2:0,
      cnt: {
         newDate:'',
         ggDate:'',
			applyCnt: 0,
			broadenCnt: 0,
			madridCnt: 0,
			renewCnt: 0,
			validCnt: 0,
			regCnt: 0
		},
      province:"",
      city:"",
		county:"",
		level:"",
      town:"",
      intCls:""
    }
  },
  components: {
   scaleBox,
   Chart,
   Table,
   Table2,
   Table3,
    Table4,
   AgentTable,
   Table10,
   Dl1,
   Dl2,
   AreaData,
   SetPession
  },
  watch:{
    
    
  },
  methods: {
   change(val){
    this.active = val
   },
   loginout(){
      localStorage.clear();
      this.$router.push({
         path:'/index'
      })
   },
   to(){
      this.$router.push({
         path:'/patentData'
      })
   },
   async getallData(){
      var thisyear = new Date().getFullYear()
      let res = await yearData({
         "applyYear": thisyear,
			"level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county
      })
      if(res.data.code === 200){
      //   console.log(res)
        var rightBottom = res.data.data;
        this.data1.option = getChartOption("data1", {
							xData: rightBottom.map((i) => i.applyYear).reverse(),
							yData: [
								rightBottom.map((i) => i.applyCnt),
								rightBottom.map((i) => i.regCnt)
							],
						});
						this.data1.option.series[0].data = rightBottom.map(
							(i) => i.applyCnt
						);
                  this.data1.option.series[0].data = this.data1.option.series[0].data.reverse()
						this.data1.option.series[1].data = rightBottom.map(
							(i) => i.regCnt
						);
                  this.data1.option.series[1].data = this.data1.option.series[1].data.reverse()
						
      }
    },
    async getallData2(){
      let res = await monthData({
         "applyYear": new Date(this.year1).getFullYear(),
			"level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county
      })
      if(res.data.code === 200){
         res.data.data.sort((x, y)=> {
			if (x.applyYear == y.applyYear) return x.applyMonth - y.applyMonth
			else return x.applyYear - y.applyYear
		})
		let map = {}
		res.data.data.forEach(item=> {
			if (!map[item.applyYear]) map[item.applyYear] = []
			map[item.applyYear].push(item.applyCnt)
		})
      this.data2.option = getChartOption("data2",map);	
      }
    },
    async getallData3(){
      let res = await monthData({
         "applyYear": new Date(this.year2).getFullYear(),
			"level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county
      })
      if(res.data.code === 200){
         res.data.data.sort((x, y)=> {
			if (x.applyYear == y.applyYear) return x.applyMonth - y.applyMonth
			else return x.applyYear - y.applyYear
		})
		let map = {}
		res.data.data.forEach(item=> {
			if (!map[item.applyYear]) map[item.applyYear] = []
			map[item.applyYear].push(item.regCnt)
		})
      this.data3.option = getChartOption("data3",map);	
      }
    },
    async getallData4(val){
      if(val == 1){
         var d = new Date(this.pickmonth)
          this.pickmonth = d.getFullYear() + '-' + this.p((d.getMonth() + 1))
      }else{
         const year = new Date().getFullYear().toString()
         let month = new Date().getMonth() + 1
         if (month < 10) month = '0' + month.toString()
         this.pickmonth = year + '-' + month.toString()
        
      }
      // console.log(this.pickmonth)
      const ym = this.pickmonth.split('-')
      let res = await monthData2({
        "year": ym[0],
		  "month": ym[1],
        "province": this.province,
         "city": this.city,
         "county":this.county,
         "town":this.town
      })
      if(res.data.code === 200){
         // console.log(res)	
         var arr = res.data.data;
         arr.forEach(item=> {
			   if (!item.date) return this.monthcount = item.count
		    })
       var arr2 = arr.slice(0,-1)
         this.data4.option = getChartOption("data4", {
							xData: arr2.map((i) => i.date),
							yData: arr2.map((i) => i.count)
						});
      }
    },
    p(s) {
      return s < 10 ? '0' + s : s
   },
   async getallData5(){
      let res = await monthData3({
         "year": new Date(this.year3).getFullYear(),
         "province": this.province,
         "city": this.city,
         "county":this.county,
         "town":this.town
      })
      if(res.data.code === 200){
        var arr = res.data.data;
         arr.forEach(item=> {
			   if (!item.date) return this.monthcount2 = item.count
		    })
       var arr2 = arr.slice(0,-1)
         this.data5.option = getChartOption("data5", {
							xData: arr2.map((i) => i.date),
							yData: arr2.map((i) => i.count)
						});
      }
    },
    yearChange1(){
      this.getallData2()
    },
    yearChange2(){
      this.getallData3()
    },
    yearChange3(){
      this.getallData4(1);
    },
    yearChange4(){
      this.getallData5();
    },     
    async getfulldata(){
      let res = await showdata({
         "level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county,
         "town":this.town,
         "intCls":this.intCls
      })
      if(res.data.code === 200){
         	// console.log(res)
            this.cnt.ggDate = res.data.data.ggDate;
            this.cnt.applyCnt = res.data.data.applyCnt
            this.cnt.broadenCnt = res.data.data.broadenCnt
            this.cnt.madridCnt = res.data.data.madridCnt
            this.cnt.renewCnt = res.data.data.renewCnt
            this.cnt.validCnt = res.data.data.validCnt
            this.cnt.regCnt = res.data.data.regCnt
            var data1 = res.data.data.newDate.split("T");
            this.cnt.newDate = data1[0]
            var data2 = res.data.data.ggDate.split("T");
            this.cnt.ggDate = data2[0]
      }
    },
    passRate(){
		const ret = (this.cnt.regCnt / this.cnt.applyCnt) * 100
		return ret.toFixed(2) + '%'
	}
  },
   mounted() {
      this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
       this.town=localStorage.getItem('town');
       this.intCls=localStorage.getItem('intCls');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
		if(this.town =="null"){
         this.town="";
      }
      if(this.intCls=="null"){
          this.intCls="";
      }
     
      if(this.level != "5"){
         this.getallData();
         this.getallData2();
         this.getallData3();
         this.getallData4();
         this.getallData5();
      }else{
         this.change('five')
      }
      this.getfulldata();
     window.parent.postMessage(
                  {
                    data: 820,
                  },
                  "*"
                );
    },
    beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#0e2140;')
            // document.querySelector('body').setAttribute('style', 'background-color:rgb(16,30,67);')
        },
}
</script>

<style scoped>
* {
		margin: 0;
		padding: 0;
      
	}
.data{
  width: 1920px;
  height: 1028px;
  background: url('../../../static/data.png') 100% no-repeat;
  background-size: cover;
  padding: 24px 0 28px;
  margin: 0 auto ;
}
.data-title{
    margin-left: 100px;
    font-size: 20px;
   font-family: DIN-Bold, DIN;
    color: #FFFFFF;
    margin-bottom: 46px;
}
.data-title>span{
    color: #18f8ff;
}
.to{
   display: inline-block;
   width: 170px;
   height: 40px;
   background: #002C8C;
   box-shadow: inset 0px 5px 15px 1px #1890FF;
   border: 1px solid #1890FF;
   text-align: center;
   line-height: 40px;
   cursor: pointer;
   color: #FFFFFF!important;
   margin-left: 850px;
}
.logout{
   display: inline-block;
   width: 120px;
   height: 40px;
   background: #002C8C;
   box-shadow: inset 0px 5px 15px 1px #1890FF;
   border: 1px solid #1890FF;
   text-align: center;
   line-height: 40px;
   cursor: pointer;
   color: #FFFFFF!important;
   margin-left: 50px;
}
.data-midlle{
    margin: 0 auto;
    height: 900px;
}
.data-midlle>div{
   float: left;
}
.middle-left{
    width: 304px;
    height: 900px;
    background: url('../../../static/data-left2.png') 100% no-repeat;
    background-size: cover;
    margin-left: 64px;
    padding-top: 55px;
}
.middle-left-but>div{
    position: relative;
    height: 22px;
    margin-bottom: 38px;
    cursor: pointer;
}
.middle-left>div>div>img{
  top:5px;
  position: absolute;
    left: 35px;
}
.middle-left>div>div>span{
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #FFFFFF;
    position: absolute;
    left: 48px;
}
.first .divm>span,.one .div1>span,.two .div2>span,.three .div3>span,.four .div4>span,.five .div5>span,.six .div6>span,.seven .div7>span,.eight .div8>span,.nine .div9>span,.ten .div10>span,.ele .div11>span,.twe .div12>span,.thr .div13>span,.thf .div14>span{
    color: #18F8FF;;
}
.middle-right{
   /* margin-left: 45px; */
}
.right-div1{
   height: 165px;
   margin-bottom: 55px;
}
.right-div1>div{
   float: left;
   text-align: center;
   margin-left: 175px;
}

.right-div1>div>div:nth-of-type(1){
   font-size: 24px;
   font-family: DIN-Bold, DIN;
   color: #BAE0FF;
}
.right-div1>div>div:nth-of-type(2){
   font-size: 16px;
font-family: PingFang SC-Medium, PingFang SC;
color: #FFFFFF;
}
:deep(.el-input__inner){
   background-color: transparent;
   color: #FFFFFF;
   padding-left: 110px;
   border: none;
}
.right-year{
   height: 40px;
}
.right-year>div{
   float: left;
   margin-left: 50px;
}
.right-year-lr{
   line-height: 40px;
   font-size: 16px;
   font-family: PingFang SC-Medium, PingFang SC;
   color: #BAE0FF;
}
.count{
   color: #18F8FF;
   margin: 10px  0 0 1050px!important;
}
</style>



