var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area-box"},[_c('div',[_c('div',{staticClass:"areabox"},[_c('img',{attrs:{"src":require('/static/images/area1.png'),"alt":""}}),_vm._m(0)]),_c('div',{staticClass:"areabox"},[_c('img',{attrs:{"src":require('/static/images/area2.png'),"alt":""}}),_vm._m(1)]),_c('div',{staticClass:"areabox"},[_c('img',{attrs:{"src":require('/static/images/area3.png'),"alt":""}}),_vm._m(2)])]),_c('div',[_c('div',{staticClass:"areabox"},[_c('img',{attrs:{"src":require('/static/images/area4.png'),"alt":""}}),_vm._m(3)]),_c('div',{staticClass:"areabox"},[_c('img',{attrs:{"src":require('/static/images/area5.png'),"alt":""}}),_vm._m(4)]),_c('div',{staticClass:"areabox"},[_c('img',{attrs:{"src":require('/static/images/area6.png'),"alt":""}}),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("123")]),_c('div',[_vm._v("总企业数（年度）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("123")]),_c('div',[_vm._v("拥有商标企业数（年度）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("123")]),_c('div',[_vm._v("新增商标数（年度）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("123")]),_c('div',[_vm._v("环比增长率（年度）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("123")]),_c('div',[_vm._v("新增拥有商标企业数（年度）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("123")]),_c('div',[_vm._v("环比增长率（年度）")])])
}]

export { render, staticRenderFns }