<template>
	<div class="area-box">
        <div>
            <div class="areabox">
                <img :src="require('/static/images/area1.png')" alt="">
                <div>
                    <div>123</div>
                    <div>总企业数（年度）</div>
                </div>
            </div>
            <div class="areabox">
                <img :src="require('/static/images/area2.png')" alt="">
                <div>
                    <div>123</div>
                    <div>拥有商标企业数（年度）</div>
                </div>
            </div>
            <div class="areabox">
                <img :src="require('/static/images/area3.png')" alt="">
                <div>
                    <div>123</div>
                    <div>新增商标数（年度）</div>
                </div>
            </div>
        </div>
		<div>
            <div class="areabox">
                <img :src="require('/static/images/area4.png')" alt="">
                <div>
                    <div>123</div>
                    <div>环比增长率（年度）</div>
                </div>
            </div>
            <div class="areabox">
                <img :src="require('/static/images/area5.png')" alt="">
                <div>
                    <div>123</div>
                    <div>新增拥有商标企业数（年度）</div>
                </div>
            </div>
            <div class="areabox">
                <img :src="require('/static/images/area6.png')" alt="">
                <div>
                    <div>123</div>
                    <div>环比增长率（年度）</div>
                </div>
            </div>
        </div>
        
	</div>

</template>
<script>

export default {
  name: '',
  data(){
    return {
	  
    }
  },
  props:{
			province:{
			type: String,
			},
			city:{
			type: String,
			},
			county:{
			type: String,
			},
			level:{
			type: String,
			}
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	
	 getData(){
		
			// 	this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/'+url,
			// 		{
			// 		"sbmc": '',
			// 		"applicant": this.applant,
			// 		"regId": '',
			// 		"intCls": '',
			// 		"from": this.from,
			// 		"size":this.size,
			// 		"total": this.total,
			// 		"level": this.level,
            //         "province": this.province,
            //         "city": this.city,
            //         "county":this.county
			// 		}
            // ).then((res) => {
            //     // console.log(res)
			// 	this.tableData.length = 0
			// 	this.tableData.push(...res.data.data)
			// 	if (res.data.data.length != 0) this.total = res.data.data[0].total	
            //   } 
			// )		
	 },
	
    
  },
   mounted() {
      this.getData();
	
    }
}
</script>

<style scoped>
.area-box{
    margin: 130px 0 0 100px;
}
.area-box>div>div{
    float: left;
    margin-right: 99px;
     margin-bottom: 65px;
}
.area-box>div>div:nth-child(3n){
    margin-right: 0;
}
	.areabox{
        width: 346px;
        height: 161px;
        background: url('../../../static/images/areaback.png') 100% no-repeat;
        background-size: cover;
        padding: 44px 0 0 56px;
    }
    .areabox>img{
        float: left;
    }
    .areabox>div{
        width: 224px;
        text-align: center;
        float: left;
        color: #fff;
    }
    .areabox>div>div:nth-of-type(1){
        margin: 28px 0 20px;
        font-weight: 600;
    }
</style>


