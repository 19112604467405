<template>
	<div class="container">
        <div v-show="listshow">
            <div class="perssion-box">
                <div class="box-title">
                    <div>用户名</div>
                    <div>省</div>
                    <div>市</div>
                    <div>区/县</div>
                    <div>街道</div>
                    <div>类别</div>
                    <div>编辑</div>
                </div>
                <div class="box-list">
                    <div v-for="(v,k) in tableData" :key="k" class="listm">
                        <div class="div1">{{v.user}}</div>
                        <div class="div2">{{v.province}}</div>
                        <div class="div3">{{v.city}}</div>
                        <div class="div4">{{v.county}}</div>
                        <div class="div5">{{v.town}}</div>
                        <div class="div6" :title="v.intCls">{{v.intCls}}</div>
                        <div class="div7" style="color:#18FFB0;" @click="edit(v.user,v.province,v.city,v.county,v.town,v.intCls)">修改</div>
                    </div>
                </div>
            </div>
               <div class="perssion-button" @click="add">新增账号</div>
        </div>
		
        <div v-show="!listshow">
            <div class="perssion-box">
                <div class="perssion-info">
                    <span>用户名</span> <input type="text" v-model="name" :disabled="namedisabled">
                    <span>密码</span><input type="text" v-model="password">
                    <span>省/市/区、县/街道</span>
                    <el-select v-model="pickpr" class="chose1" @change="chose1" style="width:135px;height:40px;margin-right:25px" disabled>
                    <el-option value="请选择"></el-option>
                    <el-option
                        v-for="item in prdata"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="pickcity" class="chose1" @change="chose2" style="width:135px;height:40px;margin-right:25px" :disabled="disabled1">
                    <el-option value="请选择"></el-option>
                    <el-option
                        v-for="item in citydata"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="pickcounty" class="chose1" @change="chose3" style="width:135px;height:40px;margin-right:25px" :disabled="disabled2">
                    <el-option value="请选择"></el-option>
                    <el-option
                        v-for="item in countrydata"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="picktown" class="chose1" @change="chose4" style="width:175px;height:40px;" :disabled="disabled3">
                    <el-option value="请选择"></el-option>
                    <el-option
                        v-for="item in towndata"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </div>
                <div class="cate-title">
                    45大类选择（选填）
                </div>
                <div class="cate-pick">
                    <div v-for="(v,k) in list" :key="k">
                        <el-checkbox v-model="cate" :label="v.id" @change="selectType" :disabled="disabled4">{{v.name}}</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="perssion-box-button">
                <div class="perssion-back" @click="back">返回</div>
               <div class="perssion-button" v-show="buttonshow" @click="insert">创建账号</div>
               <div class="perssion-button" v-show="!buttonshow" @click="insert2">修改密码</div>
            </div>
            
        </div>
	</div>
</template>
<script>
import {nextaddress} from "../../api/api";
export default {
  name: '',
  data(){
    return {
        listshow:true,
        name:'',
        namedisabled:false,
        password:'',
	  tableData:[],
      list: [
        { id: "01", name: "01.化学原料"},
        { id: "02", name: "02.颜料油漆"},
        { id: "03", name: "03.日化用品"},
        { id: "04", name: "04.燃料油脂"},
        { id: "05", name: "05.医药"},
        { id: "06", name: "06.金属材料"},
        { id: "07", name: "07.机械设备"},
        { id: "08", name: "08.手工器械"},
        { id: "09", name: "09.科学仪器"},
        { id: "10", name: "10.医疗器械"},
        { id: "11", name: "11.灯具空调"},
        { id: "12", name: "12.运输工具"},
        { id: "13", name: "13.军火烟火"},
        { id: "14", name: "14.珠宝钟表"},
        { id: "15", name: "15.乐器"},
        { id: "16", name: "16.办公用品"},
        { id: "17", name: "17.橡胶制品"},
        { id: "18", name: "18.皮革皮具"},
        { id: "19", name: "19.建筑材料"},
        { id: "20", name: "20.家具"},
        { id: "21", name: "21.厨房洁具"},
        { id: "22", name: "22.绳网袋蓬"},
        { id: "23", name: "23.纱线丝"},
        { id: "24", name: "24.布料床单"},
        { id: "25", name: "25.服装鞋帽"},
        { id: "26", name: "26.钮扣拉链"},
        { id: "27", name: "27.地毯席垫"},
        { id: "28", name: "28.健身器材"},
        { id: "29", name: "29.食品"},
        { id: "30", name: "30.方便食品"},
        { id: "31", name: "31.农林生鲜"},
        { id: "32", name: "32.啤酒饮料"},
        { id: "33", name: "33.酒"},
        { id: "34", name: "34.烟草烟具"},
        { id: "35", name: "35.广告销售"},
        { id: "36", name: "36.金融物管"},
        { id: "37", name: "37.建筑修理"},
        { id: "38", name: "38.通讯服务"},
        { id: "39", name: "39.运输贮藏"},
        { id: "40", name: "40.材料加工"},
        { id: "41", name: "41.教育娱乐"},
        { id: "42", name: "42.科技服务"},
        { id: "43", name: "43.餐饮住宿"},
        { id: "44", name: "44.医疗园艺"},
        { id: "45", name: "45.社会服务"},
      ],
      cate:[],
      pickpr:'请选择',
      pickcity:'请选择',
      pickcounty:'请选择',
      picktown:'请选择',
      prdata:[],
      citydata:[],
     countrydata:[],
      towndata:[],
      disabled1:false,
      disabled2:false,
      disabled3:false,
      disabled4:false,
      nextlevel:'',
      current:'',
      province:"",
      city:"",
		county:"",
		level:"",
        buttonshow:true
    }
  },
 
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
    add(){
        this.name = "";
                this.password = "";
                this.cate = [];
                this.namedisabled = false;
                 this.disabled1 = true;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = false;
        this.listshow = false;
    },
    back(){
        this.listshow = true;
    },
	chose1(){
        
    },
    chose2(){
        this.pickcounty = "请选择"
        this.picktown = "请选择"
        this.nextlevel = "3"
        this.getnext((res)=>{
            this.countrydata = res
        })
    },
    chose3(){
        this.picktown = "请选择"
         this.nextlevel = "4"
        this.getnext((res)=>{
            this.towndata = res
        })
    },
    chose4(){
      
    },

    edit(val1,val2,val3,val4,val5,val6){
        this.listshow = false;
        this.buttonshow = false;
        this.namedisabled = true;
        this.disabled1 = true;
        this.disabled2 = true;
        this.disabled3 = true;
        this.disabled4 = true;
        this.name = val1;
        this.pickpr =val2;
        this.pickcity = val3;
        if(val4 != "null"){
            this.pickcounty = val4
        }
        if(val5 != "null"){
            this.picktown = val5
        }
        if(val6 != "null"&&val6!= ""&&val6 != null){
            this.cate = []
            this.cate = val6.split(',')
        }

    },
    insert2(){
        if(this.password == ''){
            this.tip("err","请输入密码")
            return false
        }else{
            this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/updateUser',
					{
                    "user":this.name,
                    "password":this.password
					}
            ).then((res) => {
               if(res.data.code == 200){
                this.tip("sucess","修改成功");
                this.name = "";
                this.password = "";
                this.cate = [];
                this.getlist();
                this.listshow = true
               }
              } 
              
			)
        }
    },
	 getlist(){
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/getNextUserList',
					{
                    "level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county
					}
            ).then((res) => {
            //    console.log(res)
               if(res.data.code == 200){
                this.tableData = res.data.data;
               }
              } 
			)		
	 },
   getnext(callback){
        this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/getNextAddress',
					{
					"level": this.nextlevel,
					"province": this.pickpr,
					"city": this.pickcity,
					"county":this.pickcounty
					}
            ).then((res) => {
               if(res.data.code == 200){
                callback(res.data.data);
               }
              } 
              
			)
     },
	selectType(){
        console.log(this.cate)
    },
     tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 1000
      })
    },
//    创建账号
    insert(){
        let current = ""
        if(this.name == ""){
            this.tip("err","请输入账号")
            return false
        }else if(this.password == ""){
            this.tip("err","请输入密码")
            return false
        }else if(this.level == "2"){
            if(this.pickcity== "请选择"){
                this.tip("err","请选择下一级行政单位")
               return false
            }
        }else if(this.level == "3"){
            if(this.pickcounty== "请选择"){
                this.tip("err","请选择下一级行政单位")
               return false
            }else{
                if(this.picktown== "请选择"){
                    this.current = "4"
                    this.picktown = ""
                }else{
                    this.current = "5"
                }
                this.insertAccount()
            }
            console.log(this.current)
        }else if(this.level == "4"){
            if(this.picktown== "请选择"){
                this.tip("err","请选择下一级行政单位")
               return false
            }else{
                this.current = "5"
            }
            this.insertAccount()
        }
    },
    insertAccount(){
        this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/insertUser',
					{
                    "user":this.name,
                    "password":this.password,
					"level": this.current,
					"province": this.pickpr,
					"city": this.pickcity,
					"county":this.pickcounty,
                    "town":this.picktown,
                    "intCls":this.cate.join(",")
					}
            ).then((res) => {
               if(res.data.code == 200){
                this.tip("sucess","创建成功");
                this.name = "";
                this.password = "";
                this.cate = [];
                this.getlist();
                this.listshow = true
               }
              } 
              
			)
    }
  },
   mounted() {
    this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
    this.pickpr = this.province
    if(this.level == "2"){
        this.nextlevel = "2"
        this.getnext((res)=>{
            this.citydata = res
        })
    }else if(this.level == "3"){
        this.disabled1 = true;
        this.pickcity = this.city;
        this.nextlevel = "3"
        this.getnext((res)=>{
            this.countrydata = res
        })
    }else if(this.level == "4"){
        this.disabled1 = true;
        this.disabled2 = true;
        this.pickcity = this.city;
        this.pickcounty = this.county;
        this.nextlevel = "4"
         this.getnext((res)=>{
            this.towndata = res
        })
    }
      this.getlist();
    }
}
</script>

<style scoped>
.container{
    margin: 64px 0 0 64px;
}
	.perssion-box{
        width: 1430px;
        height: 560px;
        background: url('../../../static/images/perssion.png') 100% no-repeat;
        /* background-size: cover; */
        margin-bottom: 20px;
        padding: 50px 0 0 50px;
    }
    .perssion-box-button{
        width: 1000px;
        height: 40px;
        margin: 0 auto;
    }
    .perssion-box-button>div{
        float: left;
         width: 320px;
        height: 40px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin-left: 120px;
        background: #1A3876;
    }
    .perssion-button{
        width: 320px;
        height: 40px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin: 0 auto;
        background: #1A3876;
    }
    .box-title{
        height: 35px;
        color: #ffffff;
        margin-bottom: 20px;
    }
    .box-title>div{
        float: left;
        width: 200px;
        font-size: 22px;
        text-align: center;
        /* border:1px solid red; */
    }
    .listm{
        height: 35px;
         color: #fff;
         margin-bottom: 10px;
         position: relative;
    }
    .listm>div{
        width: 202px;
        text-align: center;
        position: absolute;
        font-size: 18px;
        /* border: 1px solid red; */
    }
    .div2{
        left: 200px;
    }
    .div3{
        left: 400px;
    }
    .div4{
        left: 600px;
    }
    .div5{
        left: 800px;
    }
    .div6{
        left: 1000px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
     .div7{
        left: 1200px;
        cursor: pointer;
    }
    .box-list{
        width: 1410px;
        height: 480px;
        overflow-y: auto;
    }
    .perssion-info{
        color: #fff;
    }
    .perssion-info>span{
        font-size: 18px;
        margin-right: 10px;
    }
     .perssion-info>input{
        width: 180px;
        height: 40px;
        padding-left: 10px;
        outline: none;
        font-size: 18px;
        border-radius: 5px;
        margin-right: 10px;
       color: #fff;
       letter-spacing: 2px;
        border: 1px solid #16AFFF;
        background: transparent;
     }
     :deep(.el-input__inner){
        height: 40px!important;
        border:1px solid #16AFFF!important;
        color: #fff !important;
        padding-left: 0!important;
        background: transparent!important;
    }
    
    .el-select-dropdown__item {
    font-size: 10px;
    color: #666666;
    font-weight: 500;
    }
    .cate-title{
        font-size: 18px;
        margin: 40px 0 30px;
        color: #fff;
    }
    .cate-pick{
        width: 1300px;
        height: 450px;
    }
    .cate-pick>div{
        width: 150px;
        margin: 0 35px 25px 0;
        float: left;
    }
    :deep(.el-checkbox__inner){
    width: 30px;
    height: 30px;
    border:1px solid #16AFFF!important;
    background: transparent!important;
}
:deep(.el-checkbox__inner::after){
    width: 10px!important;
    height: 20px!important;
    left: 9px!important;
}
:deep(.el-checkbox__label){
    font-size: 18px;
    color: #FFFFFF  !important;
    opacity: .8;
}
   :deep(.el-checkbox__input.is-checked+.el-checkbox__label ){
    color: #16AFFF!important;
    background: transparent;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner ){
    border-color: #16AFFF;
      background: transparent;
  }

</style>
<style>
.el-message{
     background: #ffffff;
}
.el-message.is-closable .el-message__content{
    color: red!important;
   
  }
</style>


