<template>
	<div class="main-container">
		<div class="tab-searh">
			<span>申请人</span>
			<input type="text" v-model.trim="applant"  placeholder="请输入申请人">
			<span class="tab-searh-span" @click="searh()">搜索</span>
			<!-- <span class="add-button" @click="daochu">添加</span> -->
			<span class="daochu" @click="daochu">导出</span>
		</div>
		<el-table :data="tableData" :class="{cup:come == 1}" @row-click="handleTableRow" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
		    <el-table-column prop="sbmc" label="商标名称" />
			<el-table-column label="图片">
				<template #default="scope">
					<el-image v-if="scope.row.imgUrl" :src="scope.row.imgUrl" 
					 style="width: 50px;height: 50px;" />
					 <!-- lazy -->
				</template>
			</el-table-column>
		    <el-table-column prop="intCls" label="国际分类"  />
			<el-table-column prop="regId" label="注册号" >
				<template v-slot="scope">
					<span>{{scope.row.regId || scope.row.intregno}}</span>
				</template>
			</el-table-column>
			<el-table-column v-if="come== 1" prop="product" label="商品或服务"  />
		    <el-table-column prop="validDate" label="有效期">
				<template v-slot="scope">
					<span style="color:#28C445" v-if="scope.row.validDate">{{scope.row.validDate.slice(0,10)}}</span>
				</template>
			</el-table-column>
		   <el-table-column prop="applicant" label="申请人">
				<template v-slot="scope">
					<span>{{scope.row.applicant || scope.row.sbsq}}</span>
				</template>
			</el-table-column>
		</el-table>
		    <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1000px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
			<!-- <div style="width:100%;height:100%;position:fixed;background-color: rgba(0, 0, 0, 0.5);z-index:1000;top:0;left:0">
				<div class="dl-add">
					<div>
						<span>名称</span>
						<input type="text">
					</div>
					<div class="add-upload">
						<div>图片</div>
						<el-upload
                                accept="image/*"
                                :http-request="upload"
                                :show-file-list="false"
                                action="">
                                <img
                                class="idimg"
                                :src="idimg ? idimg : require('/static/images/dladd.png')"
                                alt=""
                                />
                            </el-upload>
					</div>
					<div>
						<span>注册号</span>
						<input type="text">
					</div>
					<div>
						<span>申请人</span>
						<input type="text">
					</div>
					<div>
						<span>有效期</span>
						<input type="text">
					</div>
					<div class="dl-button">
						<span>取消</span>
						<span>确认</span>
					</div>
					
				</div>
			</div> -->
			<div v-show="imgShow" style="width:100%;height:100%;position:fixed;background-color: rgba(0, 0, 0, 0.5);z-index:1000;top:0;left:0">
				<div class="dl-img">
					<i class="el-icon-close" @click="cha"></i>
					<img :src="idimg" alt="">
					<div class="dl-next">
						<span @click="up">上一页</span><span @click="next">下一页</span>
					</div>
				</div>
			</div>
	</div>
</template>
<script>
import { Loading } from "element-ui";
import {knowledgeAnnouncement, knowledgeDetail} from "../../api/api";
export default {
  name: '',
  data(){
    return {
	  applant:'',
	  from:0,
	  total:0,
	  size:20,
	  tableData:[],
	  height:506,
	  idimg:'',
	  report:[],
	  imgShow:false,
	  mt:'',
	  num:''
    }
  },
  props:{
		come: {
				type: Number,
				default: () => {
					return 1
				},
			}
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	upload(){

	},
	cha(){
		this.imgShow = false;
		this.report = [];
		this.mt= "";
		this.num = "";
	},
	handleTableRow(row){
		if(this.come == 1){
			// console.log(row)
			this.knowledgeDetail(row)
		}
	},
	up(){
		this.num = Number(this.num) - 1;
		if(this.num != 0){
			this.$http.post(this.GLOBAL.new_url+'/trademark/service/ai/knowledgeAnnouncement',{
                             trialNum:this.mt,
                             pageNum:this.num
                    },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                        // console.log(res)
                        if(res.data.code == '200'){
							this.idimg = res.data.data.imageUrl
                        }
                          
                    })
		}
	},
	next(){
		this.num = Number(this.num) + 1;
		if(this.num != 0){
			this.$http.post(this.GLOBAL.new_url+'/trademark/service/ai/knowledgeAnnouncement',{
                             trialNum:this.mt,
                             pageNum:this.num
                    },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                        // console.log(res)
                        if(res.data.code == '200'){
					
							this.idimg = res.data.data.imageUrl
                        }
                          
                    })
		}
	},
	async knowledgeDetail(row) {
      let res = await knowledgeDetail({
        category:row.intCls,
        regNum:row.regId
      })
      if (res.data.code === 200) {
        this.report = res.data.data.trademarkAnnouncementList
		// console.log(res.data.data)
		this.mt = res.data.data.AnnouncementInstance
                 for(var i =0;i<this.report.length;i++){
                     if(this.mt == this.report[i].TrialNum){
                         this.num = this.report[i].PageNum;
                         this.$http.post(this.GLOBAL.new_url+'/trademark/service/ai/knowledgeAnnouncement',{
                             trialNum:this.mt,
                             pageNum:this.num
                    },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                        // console.log(res)
                        if(res.data.code == '200'){
							this.imgShow = true;
							this.idimg = res.data.data.imageUrl
                        }
                          
                    })
                     }
                 }
      }
    
    },
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
	 getData(){
		// console.log(this.come)
				let url = ''
				if (this.come == 1) {
					url = 'dlbz'
				} else if (this.come == 2) {
					url = 'cmsb'
				} 
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/'+url,
					{
					"sbmc": '',
					"applicant": this.applant,
					"regId": '',
					"intCls": '',
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					"intCls":this.intCls,
					"town":this.town
					}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)		
	 },
	 daochu(){
			if(this.tableData.length == 0 ){
				return false;
			}
			Loading.service({ fullscreen: true })
			    let url = ''
				if (this.come == 1) {
					url = 'getDLBZDataExcel'
				} else if (this.come == 2) {
					url = 'getCMSBDataExcel'
				} 
			this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/' + url,
					{
					"sbmc": '',
					"applicant": this.applant,
					"regId": '',
					"intCls": '',
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					"intCls":this.intCls,
					"town":this.town
					}
            ).then((res)=>{
			 if(res.data.code == 200){
				Loading.service().close();
				const link = document.createElement("a");
				link.style.display = "none";
				link.href = res.data.data;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				}
			}).catch((err) =>{
                       Loading.service().close();
					   console.log(err);
                    })
		 },
		useHeight(){
						return new Promise((res) => {
							this.$nextTick(() => {
								let heightCount = 0
								let mainEl = document.querySelector('.table-container' + this.index)
								heightCount = mainEl?.clientHeight
								heightCount = heightCount - 36 - 45
								this.height= heightCount
								res(heightCount)
							})
						})				
					},
			searh(){
				this.from = 0
				this.getData()
			}     
  },
   mounted() {
	this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
       this.town=localStorage.getItem('town');
       this.intCls=localStorage.getItem('intCls');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
		if(this.town =="null"){
         this.town="";
      }
      if(this.intCls=="null"){
          this.intCls="";
      }
      this.getData();
	  this.useHeight()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	.tab-searh{
		color: #BAE0FF;
		margin: 0 0 25px 80px;
		
	}
	.tab-searh>input{
		width: 210px;
		height: 38px;
		outline: none;
		font-size: 16px;
		padding-left: 10px;
		background-color: transparent;
		border-radius: 4px;
		border: 1px solid #BAE0FF;
		margin: 0 25px;
		color: #fff;
	}
	input::-webkit-input-placeholder{
		color: #BAE0FF;
	}
	.tab-searh-span{
		display: inline-block;
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		width: 76px;
		height: 32px;
		cursor: pointer;
		color: #ffffff;
		font-size: 18px;
		background: #264C8F;
	}
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
:deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
.add-button{
	display:inline-block;
	width: 80px;
	color: #fff !important;
	height: 32px;
	text-align: center;
	margin-left: 780px;
	line-height: 32px;
	background: #264C8F;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid #264C8F;
}
.daochu{
	display:inline-block;
	width: 80px;
	color: #fff !important;
	height: 32px;
	text-align: center;
	/* margin-left: 50px; */
	margin-left: 900px;
	line-height: 32px;
	background: #264C8F;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid #264C8F;
}
.dl-add{
	width: 704px;
	padding-top: 40px;
	height: 500px;
	background: url('../../../static/images/dlback.png') 100% no-repeat;
    background-size: cover;
	position: absolute;
	top:50%;
	left: 50%;
	transform: translate(-50%,-50%);
	text-align: center;
	color: #fff;
}
.dl-add>div{
	margin-bottom: 32px;
}
.dl-add>div>input{
	width: 290px;
	height: 40px;
	padding-left: 10px;
	background: transparent;
	outline: none;
	color: white;
	border-radius: 4px;
	margin-left: 24px;
	border: 1px solid #00FFC2;
}
.add-upload{
	text-align: start!important;
	height: 80px;
}
.add-upload>div:nth-of-type(1){
	float: left;
	margin:0 24px 0 175px;
}
.idimg{
	width: 80px;
	height: 80px;
}
.dl-button>span{
	display: inline-block;
	width: 160px;
	height: 44px;
	border-radius: 4px;
	line-height: 44px;
	text-align: center;
	background: #0E3637;
	cursor: pointer;
	margin-right: 48px;
}
.dl-button>span:nth-of-type(2){
	margin-right: 0;
}
.cup{
	cursor: pointer;
}
:deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
 .dl-img{
	width: 40%;
	height: 90%;
	background: #fff;
	position: absolute;
	top:50%;
	left: 50%;
	transform: translate(-50%,-50%);
 }
 .el-icon-close{
	font-size: 30px;
	cursor: pointer;
	position: absolute;
	top:5px;
	right:10px;
 }
 .dl-img>img{
	width: 90%;
	height: 90%;
	position: absolute;
	top:35px;
	left: 50%;
	transform: translate(-50%,0);
	border:1px solid gray;
 }
 .dl-next{
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 20px;
	font-size: 20px;
 }
 .dl-next>span{
	cursor: pointer;
 }
 .dl-next>span:nth-of-type(1){
	margin-right: 50px;
 }
</style>
<style>
.el-loading-parent--relative{
	position: static!important;
}
.el-loading-mask{
	background:rgba(0, 0, 0, 0.7)!important;
}
</style>

