<template>
	<div class="main-container">
		<el-table :data="tableData" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height">
			<el-table-column type="index" label="序号" width="100" />
		    <el-table-column prop="applicantName" width="500" align="center"  label="企业名称" />
			<el-table-column prop="createTime" label="创建时间"  width="400" align="center">
				<template v-slot="scope">
					<span style="color:#28C445">{{scope.row.createTime}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="lastTime" label="上次使用时间"  width="400" align="center">
				<template v-slot="scope">
					<span style="color:#F5E055">{{scope.row.lastTime}}</span>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
  name: '',
  data(){
    return {
	  tableData:[],
	  height:610,
	  province:"",
      city:"",
	  county:"",
	  level:"",
      town:"",
    }
  },
  components: {
   
  },
  watch:{
    
  },
  methods: {
	getData(){
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/getApplicantUsed',{
					"province": this.province,
					"city": this.city,
					"county":this.county,
					"town":this.town
				}).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
              } 
			)		
	 }
  },
   mounted() {
	this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
       this.town=localStorage.getItem('town');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
		if(this.town =="null"){
         this.town="";
      }
      this.getData()
    }
}	
</script>

<style scoped>
	.main-container {
		margin-top:80px;
		height: 100%;
	}
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #ffffff;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
  :deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
</style>
